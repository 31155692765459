<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addtype">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="typelist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column
        prop="product_name"
        :label="$t('flmc')"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加产品分类弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="typeform"
        label-width="90px"
        ref="typeform"
        :rules="formrules"
      >
        <el-form-item :label="$t('bizhong')" prop="typename">
          <el-input
            v-model="typeform.typename"
            :placeholder="$t('qxr')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑产品分类弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="editform"
        label-width="90px"
        ref="editform"
        :rules="formrules"
      >
        <el-form-item :label="$t('bizhong')" prop="typename">
          <el-input
            v-model="editform.typename"
            :placeholder="$t('qsr')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      typelist: [],
      total: 0,
      loading: true,
      // 添加分类数据对象
      typeform: {
        typename: ''
      },
      dialogvisible: false,
      formrules: {
        typename: [{ required: true, message: this.$t('qsr'), trigger: 'blur' }]
      },
      // 编辑分类数据对象
      editform: {
        typename: '',
        id: ''
      },
      editdialogvisible: false
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/product/typelist', {
        params: this.queryinfo
      })
      this.typelist = data.data.list
      this.loading = false
    },
    // 添加分类弹窗
    addtype() {
      this.dialogvisible = true
    },
    // 关闭添加弹窗
    handleClose() {
      this.$refs.typeform.resetFields()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 提交添加分类
    submit() {
      this.$refs.typeform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/product/addtype',
          this.typeform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.handleClose()
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/product/edittype/id/' + id)
      this.editform.typename = data.data.product_name
      this.editform.id = data.data.id
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.editform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/product/edittype',
          this.editform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.editclose()
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.editdialogvisible = false
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.editform.resetFields()
      this.editdialogvisible = false
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('queding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      })
        .then(async () => {
          const { data } = await this.$http.post('/admin/product/deltype', {
            id
          })

          if (data) {
            if (data.code === 200) {
              this.$message.success(this.getlang(data.msg))
              this.getlist()
            } else {
              this.$message.error(this.getlang(data.msg))
            }
          }
        })
        .catch(() => {
          // this.$message.info('已取消删除')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
</style>
